@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Inter", sans-serif;
        line-height: 1.3;
    }

    table {
    }

    th,
    td {
        @apply py-3 px-2.5 text-sm text-left border-y border-gray-200;
    }
    tr.hover-bg {
        @apply hover:bg-gray-100 cursor-pointer;
    }
    tr.selected {
        @apply bg-blue-100;
    }
    tr.selected-gray {
        @apply bg-gray-100;
    }
    thead {
        @apply bg-neutral-100;
    }
    th {
        @apply font-semibold;
    }
    td.hover-underline:hover {
        text-decoration: underline;
    }

    a {
        @apply text-sky-600 hover:underline;
    }

    p {
        padding: 4px 0;
    }

    /* input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
        @apply text-gray-700 w-full border border-gray-400 rounded leading-tight focus:outline-none active:outline-none focus:border-gray-500;
    }

    input[type="color"] {
        @apply rounded leading-tight focus:outline-none active:outline-none h-24;
    } */

    /* input:invalid {
        @apply border-red-500 text-red-500;
    } */

    /* select {
        @apply appearance-none block w-full text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500;
    } */

    /* select:invalid {
        @apply border-red-500 text-red-500;
    } */

    /* .jsonforms .control input,
    .jsonforms .control textarea,
    .jsonforms .control select {
        @apply w-full rounded-md border focus:outline-none focus:border-black focus:ring-1 focus:ring-black p-2;
    } */

    /* .jsonforms .control label {
        @apply mb-2 inline-block mt-2;
    } */

    .form-base input,
    .form-base select,
    .form-base textarea,
    .form-base .dropdown {
        @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
    }
    .form-base input[type="color"] {
        @apply h-11 p-1;
    }
    .form-base select {
        @apply w-full;
    }
    .form-base .dropdown {
        @apply p-1;
    }

    .form-base label,
    .form-base p {
        @apply block mb-2 text-sm font-medium text-gray-900;
    }

    .form-base .error input,
    .form-base .error textarea,
    .form-base .error select,
    .form-base .error .dropdown {
        @apply bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500;
    }

    .error,
    .form-base .error,
    .form-base .error label,
    .form-base .error p {
        @apply text-red-700;
    }

    .form-base .error p {
        @apply my-1 text-xs;
    }

    .form-base .success input,
    .form-base .success textarea,
    .form-base .success select {
        @apply bg-green-50 border-green-500 text-green-900 placeholder-green-700 focus:ring-green-500 focus:border-green-500;
    }

    .form-base .success label,
    .form-base .success p {
        @apply text-green-700;
    }

    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        display: none;
    }

    .info {
        @apply bg-sky-600 text-white;
    }

    .light {
        @apply bg-white text-gray-800;
    }
    .indigo {
        @apply bg-indigo-200 text-black;
    }
    .danger {
        @apply bg-red-600 text-white;
    }
    .warning {
        @apply bg-yellow-600 text-white;
    }
    .success {
        @apply bg-green-600 text-white;
    }

    a.button {
        text-decoration: none;
    }

    .button {
        @apply rounded px-3 py-1 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2;
    }
    button:focus-visible {
        outline: 2px solid #007bff;
        outline-offset: 2px;
    }

    .button.info {
        @apply shadow-sm hover:bg-sky-500 focus-visible:outline-sky-600;
    }

    .button.indigo {
        @apply shadow-sm hover:bg-indigo-300 focus-visible:bg-indigo-200;
    }

    .button.light {
        @apply shadow-sm hover:bg-gray-100  border border-gray-400;
    }

    .button.danger {
        @apply shadow-sm hover:bg-red-500 focus-visible:outline-red-600;
    }

    .button.warning {
        @apply shadow-sm hover:bg-yellow-500 focus-visible:outline-yellow-600;
    }

    .button.success {
        @apply shadow-sm hover:bg-green-500 focus-visible:outline-green-600;
    }

    .button.link {
        @apply text-sky-600 hover:underline font-normal p-0;
    }

    .buttons {
        @apply flex flex-row gap-1 border-t pt-3 mt-3;
    }

    .btn {
        @apply border-none bg-transparent font-bold text-sm p-1;
    }
    .btn.danger {
        @apply text-red-700;
    }

    h1 {
        @apply font-bold text-2xl;
    }

    h2 {
        @apply font-semibold text-sm;
    }

    hr {
        @apply border-t border-gray-200 my-4;
    }
    a {
        text-decoration: none;
        /* @apply text-blue-500 hover:underline; */
    }

    .overlay-container {
        position: relative;
    }

    .overlay-content {
        /* padding: 20px; */
        text-align: center;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* Gray overlay */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
        text-align: center;
        z-index: 1;
        @apply rounded-sm;
    }

    /* input.disabled, */
    .unreachable {
        @apply bg-gray-100 text-gray-500;
    }

    button.disabled {
        /* opacity: 0.9; */
        background-color: #e0e0e0;
        color: #a0a0a0;
        /* border: 1px solid #cccccc; */
        cursor: not-allowed;
        box-shadow: none;
    }

    button.disabled:hover {
        background-color: #e0e0e0 !important;
    }

    #calibration-instructions ol {
        margin-left: 1rem;
        margin-top: 0.5rem;
    }
    #calibration-instructions ol > * + * {
        margin-top: 0.75rem;
    }
    #calibration-instructions ul {
        margin-left: 2.5rem;
        list-style: disc;
    }
}
